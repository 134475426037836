import "core-js/modules/web.dom.iterable";
import "core-js/modules/es7.symbol.async-iterator";
import "core-js/modules/es6.symbol";
import "core-js/modules/es6.string.iterator";
import "core-js/modules/es6.array.from";
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.regexp.to-string";

function _createForOfIteratorHelper(o) { if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (o = _unsupportedIterableToArray(o))) { var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var it, normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { fetchTurnoverBillList, updateTranover, checkTurnoverBill, payTurnoverBill, getPayInfoTurnoverBill } from "@/api/finance";
import "@/utils/global.js";
import { transBillState, downloadFun } from "@/utils/util";
import Pagination from "@/components/Pagination";
import { getToken } from "@/utils/cache";
export default {
  name: "TurnoverBill",
  components: {
    Pagination: Pagination
  },
  data: function data() {
    return {
      // 查询字段
      searchForm: {
        page: 1,
        per_page: 20,
        start_time: "",
        end_time: "",
        keyword: "",
        filter: {
          status: ""
        }
      },
      // 表格数据
      dataList: [],
      // 总数
      totalPage: 0,
      tableLoading: false,
      btnLoading: false,
      selectCount: 0,
      selectList: [],
      memberDialog: false,
      checkForm: {
        id: '',
        bill_no: '',
        member_realname: '',
        member_mobile: '',
        agent_name: '',
        property_name: '',
        start_at: '',
        end_at: '',
        settle_cycle: '',
        order_count: 0,
        order_money: 0,
        settle_ratio: 0,
        settle_money: 0,
        fee_money: 0,
        pay_money: 0,
        status: 2
      },
      total_settle_money: 0,
      total: 0,
      agentLoading: false,
      agentOptions: [],
      checkDialog: false,
      payDialog: false,
      payForm: {
        id: '',
        bill_no: '',
        agent_name: '',
        member_realname: '',
        member_mobile: '',
        property_name: '',
        start_at: '',
        end_at: '',
        settle_cycle: '',
        settle_ratio: 0,
        settle_money: 0,
        fee_money: 0,
        pay_money: 0,
        enc_true_name: '',
        bank_code: '',
        bank_no: '',
        status: 2
      },
      weixinPayLoading: false,
      cashPayLoading: false
    };
  },
  created: function created() {
    this.getTableData();
  },
  methods: {
    getTableData: function getTableData() {
      var _this = this;

      this.tableLoading = true;
      var data = this.searchForm;
      fetchTurnoverBillList(data).then(function (response) {
        console.log(response);
        _this.total_settle_money = response.meta.total_settle_money;
        _this.dataList = response.data;
        _this.totalPage = response.meta.total;
        _this.total = response.meta.total;
        _this.tableLoading = false;
      }).catch(function (err) {
        _this.tableLoading = false;

        _this.$message({
          type: "error",
          message: err.data["message"]
        });
      });
    },
    handleSearch: function handleSearch() {
      this.searchForm.page = 1;
      this.getTableData();
    },
    handlePageCurrent: function handlePageCurrent(val) {
      console.log(val);
      this.searchForm.page = val;
      this.getTableData();
    },
    handlePageSize: function handlePageSize(val) {
      console.log(val);
      this.searchForm.per_page = val;
      this.searchForm.page = 1;
      this.getTableData();
    },
    handleDownload: function handleDownload() {
      console.log("导出");
      this.btnLoading = true;
      var data = this.searchForm;
      var url = process.env.VUE_APP_URL + "/orders/download?token=".concat(getToken(), "&keyword=").concat(data.keyword || "", "&start_time=").concat(data.start_time, "&end_time=").concat(data.end_time, "&status=").concat(data.status);
      console.log(url);
      downloadFun(url, "订单列表.csv");
      this.btnLoading = false;
    },
    handleReturnBtn: function handleReturnBtn() {
      var _this2 = this;

      this.$refs["dialogForm"].validate(function (valid) {
        if (valid) {
          var data = _this2.dialogForm;
          updateReturn(data).then(function (res) {
            console.log(res);
            _this2.dataDialog = false;

            var _iterator = _createForOfIteratorHelper(_this2.dataList),
                _step;

            try {
              for (_iterator.s(); !(_step = _iterator.n()).done;) {
                var v = _step.value;

                if (v.ordersn === data.order_id) {
                  v.status = 5;
                  v.refund = data.money;
                }
              }
            } catch (err) {
              _iterator.e(err);
            } finally {
              _iterator.f();
            }

            _this2.$message({
              type: "success",
              message: "退款成功"
            });
          }).catch(function (err) {
            _this2.$message({
              type: "warning",
              message: err.data["message"]
            });
          });
        }
      });
    },
    handleCheck: function handleCheck(row) {
      var _this3 = this;

      this.checkDialog = true;
      this.checkForm = {
        id: row.id,
        bill_no: row.bill_no,
        agent_name: row.agent_name,
        member_realname: row.member_realname,
        member_mobile: row.member_mobile,
        property_name: row.property_name,
        settle_cycle: row.settle_cycle,
        start_at: row.start_at,
        end_at: row.end_at,
        settle_ratio: row.id,
        order_count: row.order_count,
        order_money: row.order_money,
        settle_money: row.settle_money,
        refund_settle_money: row.refund_settle_money,
        pay_money: row.pay_money,
        fee_money: row.fee_money,
        status: 2
      };
      this.$nextTick(function () {
        _this3.$refs["checkForm"].clearValidate();
      });
    },
    handleCheckBtn: function handleCheckBtn() {
      var _this4 = this;

      this.$refs["checkForm"].validate(function (valid) {
        if (valid) {
          var data = {
            id: _this4.checkForm.id,
            status: _this4.checkForm.status
          };
          checkTurnoverBill(data).then(function (res) {
            _this4.checkDialog = false;

            var _iterator2 = _createForOfIteratorHelper(_this4.dataList),
                _step2;

            try {
              for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
                var v = _step2.value;

                if (v.id === data.id && data.status == 2) {
                  v.status = 2;
                }
              }
            } catch (err) {
              _iterator2.e(err);
            } finally {
              _iterator2.f();
            }

            _this4.$message({
              type: "success",
              message: '审核完成'
            });
          }).catch(function (err) {
            _this4.$message({
              type: "warning",
              message: err.data["message"]
            });
          });
        }
      });
    },
    handlePay: function handlePay(row) {
      var _this5 = this;

      getPayInfoTurnoverBill(row.id).then(function (res) {
        _this5.payDialog = true;
        _this5.payForm = {
          id: row.id,
          bill_no: row.bill_no,
          agent_name: row.agent_name,
          member_realname: row.member_realname,
          member_mobile: row.member_mobile,
          property_name: row.property_name,
          settle_cycle: row.settle_cycle,
          start_at: row.start_at,
          end_at: row.end_at,
          settle_ratio: row.id,
          settle_money: row.settle_money,
          refund_settle_money: row.refund_settle_money,
          pay_money: row.pay_money,
          fee_money: row.fee_money,
          bank_no: res.data.bank_no,
          enc_true_name: res.data.enc_true_name,
          bank_code: res.data.bank_code,
          bank_code_name: res.data.bank_code_name,
          status: 2
        };

        _this5.$nextTick(function () {
          _this5.$refs["payForm"].clearValidate();
        });
      }).catch(function (err) {
        _this5.$message({
          type: "warning",
          message: err.data["message"]
        });
      });
    },
    handleCashPayBtn: function handleCashPayBtn() {
      var _this6 = this;

      this.cashPayLoading = true;
      this.$confirm("选择手动处理 , 系统不进行任何打款操作!<br/>请确认你已通过线下方式为用户打款!!!<br/>是否进行手动处理?", '提示', {
        dangerouslyUseHTMLString: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        var data = {
          id: _this6.payForm.id,
          pay_type: 'cash'
        };
        payTurnoverBill(data).then(function (res) {
          _this6.payDialog = false;
          _this6.cashPayLoading = false;

          _this6.getTableData();

          _this6.$message({
            type: 'success',
            message: '打款成功'
          });
        }).catch(function (err) {
          _this6.$message({
            type: "warning",
            message: err.data["message"]
          });
        });
      }).catch(function () {
        _this6.cashPayLoading = false;
      });
    },
    handleWeixinPayBtn: function handleWeixinPayBtn() {
      var _this7 = this;

      this.weixinPayLoading = true;
      this.$confirm('确认打款到此用户的微信零钱?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        var data = {
          id: _this7.payForm.id,
          pay_type: 'weixin'
        };
        payTurnoverBill(data).then(function (res) {
          _this7.payDialog = false;
          _this7.weixinPayLoading = false;

          _this7.getTableData();

          _this7.$message({
            type: 'success',
            message: '打款成功'
          });
        }).catch(function (err) {
          _this7.weixinPayLoading = false;

          _this7.$message({
            type: "warning",
            message: err.data["message"]
          });
        });
      }).catch(function () {
        _this7.weixinPayLoading = false;
      });
    },
    handleTime: function handleTime(val) {
      if (val) {
        this.searchForm["start_time"] = val[0];
        this.searchForm["end_time"] = val[1];
      } else {
        this.searchForm["start_time"] = "";
        this.searchForm["end_time"] = "";
      }
    },
    resetForm: function resetForm(formName) {
      this.searchForm["start_time"] = "";
      this.searchForm["end_time"] = "";
      this.$refs[formName].resetFields();
    },
    remoteAgent: function remoteAgent(query) {
      var _this8 = this;

      console.log("agent remote");

      if (query !== "") {
        this.agentLoading = true;
        setTimeout(function () {
          _this8.agentLoading = false;
          var data = {
            keyword: query
          };
          fetchAgentList(data).then(function (res) {
            console.log(res);
            _this8.agentOptions = res.data;
          });
        }, 100);
      } else {
        this.agentOptions = [];
      }
    },
    BillStatus: function BillStatus(status) {
      switch (status) {
        case 0:
          return '结算中';

        case 1:
          return '待审核';

        case 2:
          return '待打款';

        case 3:
          return '已打款';

        default:
          return '未知';
      }
    },
    SettleType: function SettleType(type) {
      switch (type) {
        case 0:
          return '手动结算';

        case 1:
          return '自动结算';

        case 2:
          return '线下结算';

        default:
          return '未知';
      }
    },
    transBillState: transBillState
  }
};